import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const ProductGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((item) => (
      <div className="column is-6">
            <section className="section">
              <h3 className="has-text-weight-semibold" style={{
              color: "#A1DE1D",
            }}>{item.header}</h3>
            <br></br>
              <PreviewCompatibleImage imageInfo={item} />
              <br></br>
              <br></br>
              <div dangerouslySetInnerHTML={{ __html: item.text.html }} />
              <br></br>
            <Link className="btn" to={item.url}>
                        Download datasheet
            </Link>
            </section>
            </div>
    ))}
  </div>
);

ProductGrid.propTypes = {
  productItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      header: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default ProductGrid;
